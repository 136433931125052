import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';

import {
	ButtonGroup,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Table,
	Row,
	Col,
	UncontrolledTooltip,
	Alert,
	Pagination,
	PaginationItem,
	PaginationLink,
} from 'reactstrap';

import { MemberAddOverlay } from './members-display/MemberAddOverlay';
import { MemberDetailsOverlay } from './members-display/MemberDetailsOverlay';

import { setting, member, statistics } from '../../api';
import { InvalidParametersException } from '../../api/exceptions/InvalidParametersException';
import { UserContext } from '../UserContext';
import { UnauthorizedException } from '../../api/exceptions/UnauthorizedException';
import { FaRegBell } from 'react-icons/fa';
import { MemberGroup } from 'api/settings/clearances';
import { MemberDB, MemberHeaderDB } from 'api/members';

export const MembersDisplay = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const searchParamQuery = searchParams.get('query');
	const { jwt, setDisplayUserLoginOverlay } = useContext(UserContext);

	const [loadingHeaders, setLoadingHeaders] = useState(true);
	const [dataHeaders, setDataHeaders] = useState<Array<MemberHeaderDB>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [loadingError, setLoadingError] = useState(false);

	const [offsetId, setOffsetId] = useState<{ previous: string; current: string }>({ previous: '', current: '' });
	const [query, setQuery] = useState<
		| 'all'
		| 'unarchived'
		| 'archived'
		| 'doc-pending'
		| 'doc-expiring'
		| 'doc-expired'
		| 'member-group-pending'
		| 'member-group-expiring'
		| 'member-group-expired'
	>('all');
	const [clearanceMemberGroupSettings, setClearanceMemberGroupSettings] = useState<Array<MemberGroup>>([]);
	const [data, setData] = useState<Array<MemberDB>>([]);
	const [displayApprovalNotification, setDisplayApprovalNotification] = useState(false);

	async function setup() {
		try {
			// Retreive the clearance settings from the backend
			await setting.clearances
				.get(jwt)
				.then((settings) => {
					setClearanceMemberGroupSettings(settings.member_groups);
				})
				.catch((error) => {
					if (error instanceof UnauthorizedException) {
						throw error;
					} else {
						console.error('HIT ERROR2');
						console.error(error);
						throw error;
					}
				});

			// Retreive the table headers from the backend
			await member.getHeaders(jwt).then((data) => {
				setLoadingHeaders(false);
				setDataHeaders(data);
			});

			// Retreive the table data from the backend
			await handleDataRefresh();

			await statistics
				.get(jwt)
				.then((data) => {
					if ((data as any).total_clearances_pending_approval != null && (data as any).total_clearances_pending_approval > 0) {
						setDisplayApprovalNotification(true);
					}
				})
				.catch((e) => {
					setLoadingError(true);
				});
		} catch (error) {
			setLoadingError(true);
			if (error instanceof UnauthorizedException) {
				setDisplayUserLoginOverlay(true, () => {
					setLoadingError(false);
				});
			} else {
				console.error(error);
				throw error;
			}
		}
	}

	useEffect(() => {
		setup();
	}, []);

	useEffect(() => {
		if (loadingError == false) {
			setup();
		}
	}, [loadingError]);

	useEffect(() => {
		handleDataRefresh();
	}, [offsetId]);

	/**
	 * This function handles the toolbar add/upload/download buttons (upper right corner) click
	 */
	const handleToolbarButtonClick = (action: 'add' | 'import') => {
		navigate(action);
	};

	/**
	 * This function handles the toolbar quick approve button (upper right corner) click
	 */
	const handleToolbarQuickApproveButtonClick = () => {
		navigate(`/administrator/quick-approval`);
	};

	/**
	 * This function handles the view details button for a record
	 */
	const handleViewMemberClick = (uid: string) => {
		navigate(uid);
	};

	/**
	 * This function will handle clicking on the alert button stating there are clearances requiring approval
	 */
	const handleApprovalAlertClick = () => {
		navigate('../../quick-approval/');
	};

	/**
	 * This function handles the data refresh
	 * @param {*} refresh
	 */
	const handleDataRefresh = (refresh = true) => {
		if (refresh === false) {
			return;
		}

		return Promise.resolve()
			.then(() => {
				setIsLoading(true);
			})
			.then(() => {
				// Retreive the table data from the backend
				return member.list(jwt, { page: offsetId.current, query_type: query != null && query !== 'all' ? query : undefined }).then((data) => {
					setIsLoading(false);
					setData(data);
				});
			})
			.catch((e) => {
				console.error(e);
				if (e instanceof InvalidParametersException) {
					setLoadingError(true);
				}
			});
	};

	/**
	 * Handle clicking on a page number in the pagination
	 */
	const handlePaginationClick = (action: 'next' | 'previous') => {
		const newOffset = { ...offsetId };

		if (action === 'previous') {
			newOffset.current = offsetId.previous;
			newOffset.previous = '';
		} else if (action === 'next') {
			newOffset.current = data[data.length - 1].docID;
			newOffset.previous = offsetId.current;
		}

		// We need to update the table
		setOffsetId(newOffset);
	};

	if (searchParamQuery != null) {
		// We have filters passed in from another component that we need to handle
		if (searchParamQuery === 'doc-pending' && query !== 'doc-pending') {
			setQuery('doc-pending');
		} else if (searchParamQuery === 'doc-expiring' && query !== 'doc-expiring') {
			setQuery('doc-expiring');
		} else if (searchParamQuery === 'doc-expired' && query !== 'doc-expired') {
			setQuery('doc-expired');
		} else if (searchParamQuery === 'member-group-pending' && query !== 'member-group-pending') {
			setQuery('member-group-pending');
		} else if (searchParamQuery === 'member-group-expiring' && query !== 'member-group-expiring') {
			setQuery('member-group-expiring');
		} else if (searchParamQuery === 'member-group-expired' && query !== 'member-group-expired') {
			setQuery('member-group-expired');
		}
	}

	return (
		<div className="content members-table">
			<Routes>
				<Route path={'add'} element={<MemberAddOverlay />} />
				<Route path={':uid'} element={<MemberDetailsOverlay />} />
			</Routes>
			<Alert
				className="approval-notification-alert"
				color="warning"
				fade={true}
				onClick={() => {
					handleApprovalAlertClick();
				}}
				isOpen={displayApprovalNotification}
			>
				<span data-notify="icon">
					<FaRegBell />
				</span>
				<span>There are clearances requiring approval</span>
			</Alert>
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<ButtonGroup className="pull-right">
								<Button
									className="btn-icon"
									id={`members-table-add-button-${Object.keys(dataHeaders).length}`}
									color="info"
									onClick={() => handleToolbarButtonClick('add')}
								>
									<i className="fa fa-plus" />
								</Button>
								<Button
									className="btn-icon"
									id={`members-table-import-button-${Object.keys(dataHeaders).length}`}
									color="info"
									onClick={() => handleToolbarButtonClick('import')}
								>
									<i className="fa fa-upload" />
								</Button>
								{/* <Button
									className="btn-icon"
									id={`members-table-download-button-${Object.keys(dataHeaders).length}`}
									color="info"
									onClick={() => handleToolbarButtonClick('download')}
								>
									<i className="fa fa-download" />
								</Button> */}
								<Button
									className="btn-icon"
									id={`members-table-quick-approve-${Object.keys(dataHeaders).length}`}
									color="info"
									onClick={() => handleToolbarQuickApproveButtonClick()}
								>
									<i className="fa fa-thumbs-up" />
								</Button>
							</ButtonGroup>

							<UncontrolledTooltip delay={0} target={`members-table-add-button-${Object.keys(dataHeaders).length}`}>
								Add Member
							</UncontrolledTooltip>
							<UncontrolledTooltip delay={0} target={`members-table-import-button-${Object.keys(dataHeaders).length}`}>
								Bulk Upload Members
							</UncontrolledTooltip>
							{/* <UncontrolledTooltip delay={0} target={`members-table-download-button-${Object.keys(dataHeaders).length}`}>
								Download Member Details
							</UncontrolledTooltip> */}
							<UncontrolledTooltip delay={0} target={`members-table-quick-approve-${Object.keys(dataHeaders).length}`}>
								Quick Approve
							</UncontrolledTooltip>
							<CardTitle tag="h4">Members</CardTitle>
						</CardHeader>
						<CardBody>
							{loadingHeaders ? (
								<div>Retrieving Data...</div>
							) : (
								<Table responsive>
									<thead className="text-primary">
										<tr>
											{Object.keys(dataHeaders).map((header) => {
												const obj: any = dataHeaders[header as any];

												return <th key={`member-header-${header}`}>{obj.name}</th>;
											})}
											<th>Member Group</th>
											<th className="text-right">Actions</th>
										</tr>
									</thead>
									{loadingError ? (
										<tbody>
											<tr>
												<td colSpan={Object.keys(dataHeaders).length + 2}>There was an error loading data.</td>
											</tr>
										</tbody>
									) : isLoading ? (
										<tbody>
											<tr>
												<td colSpan={Object.keys(dataHeaders).length + 2}>Loading...</td>
											</tr>
										</tbody>
									) : (
										<tbody>
											{data != null && data.length > 0 ? (
												data.map((row, key) => {
													const memberClearanceGroupName = clearanceMemberGroupSettings.filter(
														(memberGroup) => memberGroup.uid === row.member_group,
													)[0];

													return (
														<tr key={`member-content-${row.docID}`}>
															{Object.keys(dataHeaders).map((header) => {
																const val = row[header];

																return <td key={`member-content-${key}-${header}`}>{val !== null ? val : ' '}</td>;
															})}
															<td>{memberClearanceGroupName != null ? memberClearanceGroupName.name : 'Unknown'}</td>
															<td className="text-right">
																<Button
																	className="btn-icon"
																	color={row.archived != null ? 'secondary' : 'info'}
																	id={`member-content-${key}-tooltip-like`}
																	size="sm"
																	type="button"
																	onClick={() => handleViewMemberClick(row.docID)}
																>
																	<i className="fa fa-user" />
																</Button>
																<UncontrolledTooltip delay={0} target={`member-content-${key}-tooltip-like`}>
																	View Details
																</UncontrolledTooltip>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan={Object.keys(dataHeaders).length + 2}>No members found.</td>
												</tr>
											)}
										</tbody>
									)}
									<tfoot>
										<tr>
											<td colSpan={Object.keys(dataHeaders).length + 2} className="table-pagniation">
												<Pagination>
													<PaginationItem>
														<PaginationLink
															previous
															href="#"
															onClick={(e) => {
																e.preventDefault();
																handlePaginationClick('previous');
															}}
														/>
													</PaginationItem>
													<PaginationItem>
														<PaginationLink
															next
															href="#"
															onClick={(e) => {
																e.preventDefault();
																handlePaginationClick('next');
															}}
														/>
													</PaginationItem>
												</Pagination>
											</td>
										</tr>
									</tfoot>
								</Table>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
