// Node modules
import * as Sentry from '@sentry/react';

// Types
import { SettingAttributesEntry } from './';

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { FailedToFetchException } from '../exceptions/FailedToFetchException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

/**
 * Database Settings General Doc
 */
export interface SettingsGeneralDB {
	site_name: string;
	data_fields: Array<SettingsDataField>;

	frontendLoginMessage?: string;
}

/**
 * The Data Field object
 */
export interface SettingsDataField {
	uid: string;
	name: string;
	description: string;
	primary: boolean;
	attributes: SettingsDataFieldAttributes;
}

/**
 * The Data Field object attributes
 */
export interface SettingsDataFieldAttributes extends SettingAttributesEntry {
	type: settings_data_field_type;
	type_attribute?: string;
	multiple: boolean;
	options?: Array<SettingsDataFieldAttributeOption>;
	displayOnSummaryPage: boolean;
	displayOnUserDashboard: boolean;
}

/**
 * The Data Field object attributes options option
 */
export interface SettingsDataFieldAttributeOption {
	uid: string;
	name: string;
	description?: string;
	attributes?: SettingsDataFieldAttributes;
}

/**
 * The values for data field types
 */
export enum settings_data_field_type {
	STRING = 'string',
	INT = 'int',
	DATE = 'date',
	EMAIL = 'email',
	DROPDOWN = 'dropdown',
	GROUP = 'group',
}

export default class SettingsGeneral {
	async get(token: string): Promise<SettingsGeneralDB> {
		try {
			const response = await fetch(`/api/v1/admin/settings/general`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.get(newToken);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 403) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async update(token: string, data: SettingsGeneralDB): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/settings/general`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(data),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.update(newToken, data);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 403) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}
}
