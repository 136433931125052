export class FailedToFetchException extends Error {
	/**
	 * Constructor
	 */
	constructor() {
		super('Failed to retrieve data');

		// If we support a stack trace, capture it
		if ('captureStackTrace' in Error) {
			Error.captureStackTrace(this, this.constructor);
		} else {
			console.error(`${this.constructor.name} Does not support captureStackTrace()!`);
		}
	}
}
