// Node modules
import * as Sentry from '@sentry/react';

// Types
/**
 * Database Notification Collection
 */
export interface NotificationDB {
	docID: string;

	status: 'created' | 'processing' | 'sent' | 'failed';
	created: Date;

	source_type?: 'report' | 'member' | 'administrator-user';
	source_id?: string;

	sent?: Date; // If not specified, has not been sent
	archived: null | Date;

	sender?: string; // If not specified, uses default sender
	private?: boolean; // If `private` is `true` then the notification will not be saved after being sent. Also, cannot require approval
	requiresApproval?: boolean; // If not specified, does not require approval
	approved?: boolean; // If `requiresApproval` is `true` then this must be set to `true` to send the notification
	dev_test?: boolean; // If true then the notification will not be sent

	to: string;
	cc?: string;
	subject: string;
	body: string;

	attachments?: Array<NotificationAttachment>;

	failed_reason?: string;
}

/**
 * The Notification Attachment entry object
 */
export interface NotificationAttachment {
	storageID: string;
	name: string;
	type: string;
}

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import NotificationAlreadyApprovedException from './exceptions/NotificationAlreadyApprovedException';
import NotificationAlreadySentException from './exceptions/NotificationAlreadySentException';
import NotificationApprovalNotRequiredException from './exceptions/NotificationApprovalNotRequiredException';
import { FailedToFetchException } from '../exceptions/FailedToFetchException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

export default class Notifications {
	async getPending(token: string): Promise<Array<NotificationDB>> {
		try {
			const response = await fetch(`/api/v1/admin/notifications/pending`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj.notifications_list;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.getPending(newToken);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async getSent(token: string): Promise<Array<NotificationDB>> {
		try {
			const response = await fetch(`/api/v1/admin/notifications/sent`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj.notifications_list;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.getSent(newToken);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async getFailed(token: string): Promise<Array<NotificationDB>> {
		try {
			const response = await fetch(`/api/v1/admin/notifications/failed`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj.notifications_list;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.getFailed(newToken);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async resend(token: string, docID: string): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/notifications/${docID}/resend`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.resend(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 461) {
				throw new NotificationAlreadySentException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async approve(token: string, docID: string): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/notifications/${docID}/approve`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.approve(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 460) {
				throw new NotificationApprovalNotRequiredException(respObj.message);
			} else if (response.status === 461) {
				throw new NotificationAlreadySentException(respObj.message);
			} else if (response.status === 462) {
				throw new NotificationAlreadyApprovedException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async archive(token: string, docID: string): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/notifications/${docID}/archive`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.archive(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 461) {
				throw new NotificationAlreadySentException(respObj.message);
			} else if (response.status === 462) {
				throw new NotificationAlreadyApprovedException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}
}
