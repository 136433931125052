import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { FaFingerprint, FaRegChartBar, FaRegClock, FaSpinner, FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import { numberToIntlFormat } from '../../lib/helpers';

import { dashboard } from '../../api';
import toast from 'react-hot-toast';

import UserContext from '../UserContext';
import { StatisticDB } from 'api/statistics';
import { UnauthorizedException } from '../../api/exceptions/UnauthorizedException';
import { PasswordExpiredException } from '../../api/exceptions/PasswordExpiredException';
import { FailedToFetchException } from '../../api/exceptions/FailedToFetchException';

export const Dashboard = () => {
	const navigate = useNavigate();
	const { jwt } = useContext(UserContext);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [data, setData] = useState<{ [key: string]: number }>({});

	useEffect(() => {
		const getContents = async () => {
			setIsLoading(true);
			try {
				const response = await dashboard.get(jwt);

				setData(response);
			} catch (error) {
				if (error instanceof UnauthorizedException) {
					navigate('/administrator/login');
				} else if (error instanceof FailedToFetchException) {
					toast.error(error.message);
				} else if (error instanceof PasswordExpiredException) {
					navigate('/administrator/login');
				} else {
					console.error('error');
					console.error(error);
					Sentry.captureException(error);
				}
			} finally {
				setIsLoading(false);
			}
		};

		getContents();
	}, []);

	return (
		<div className="content">
			<Row>
				<Col lg="3" md="6" sm="6">
					<Card className="card-stats">
						<CardBody>
							<Row>
								<Col md="4" xs="5">
									<div className="icon-big text-center">
										<FaFingerprint className="text-success" />
									</div>
								</Col>
								<Col md="8" xs="7">
									<div className="numbers">
										<p className="card-category">Total Members</p>
										<CardTitle tag="p">
											{isLoading ? (
												<FaSpinner size={12} color="#ccc" className="fa-spin" />
											) : data.total_member_count != null ? (
												data.total_member_count > 9999 ? (
													`${numberToIntlFormat(9999)}+`
												) : (
													numberToIntlFormat(data.total_member_count)
												)
											) : (
												'?'
											)}
										</CardTitle>
										<p />
									</div>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<hr />
							<div className="stats">
								<Link to={'../member?query=all'}>
									<i className="fa fa-list" />
									View All
								</Link>
							</div>
						</CardFooter>
					</Card>
				</Col>
				<Col lg="3" md="6" sm="6">
					<Card className="card-stats">
						<CardBody>
							<Row>
								<Col md="4" xs="5">
									<div className="icon-big text-center">
										<FaTimes className="text-danger" />
									</div>
								</Col>
								<Col md="8" xs="7">
									<div className="numbers">
										<p className="card-category">Expired Credentials</p>
										<CardTitle tag="p">
											{isLoading ? (
												<FaSpinner size={12} color="#ccc" className="fa-spin" />
											) : data.total_clearances_expired != null ? (
												data.total_clearances_expired > 9999 ? (
													`${numberToIntlFormat(9999)}+`
												) : (
													numberToIntlFormat(data.total_clearances_expired)
												)
											) : (
												'?'
											)}
										</CardTitle>
										<p />
									</div>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<hr />
							<div className="stats">
								<Link to={'../member?query=member-group-expired'}>
									<i className="fa fa-list" />
									View Groups
								</Link>
							</div>
							<div className="stats">
								<Link to={'../member?query=doc-expired'}>
									<i className="fa fa-list" />
									View All
								</Link>
							</div>
						</CardFooter>
					</Card>
				</Col>
				<Col lg="3" md="6" sm="6">
					<Card className="card-stats">
						<CardBody>
							<Row>
								<Col md="4" xs="5">
									<div className="icon-big text-center">
										<FaRegChartBar className="text-warning" />
									</div>
								</Col>
								<Col md="8" xs="7">
									<div className="numbers">
										<p className="card-category">Expiring Credentials</p>
										<CardTitle tag="p">
											{isLoading ? (
												<FaSpinner size={12} color="#ccc" className="fa-spin" />
											) : data.total_clearances_expiring != null ? (
												data.total_clearances_expiring > 9999 ? (
													`${numberToIntlFormat(9999)}+`
												) : (
													numberToIntlFormat(data.total_clearances_expiring)
												)
											) : (
												'?'
											)}
										</CardTitle>
										<p />
									</div>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<hr />
							<div className="stats">
								<Link to={'../member?query=member-group-expiring'}>
									<i className="fa fa-list" />
									View Groups
								</Link>
							</div>
							<div className="stats">
								<Link to={'../member?query=doc-expiring'}>
									<i className="fa fa-list" />
									View All
								</Link>
							</div>
						</CardFooter>
					</Card>
				</Col>
				<Col lg="3" md="6" sm="6">
					<Card className="card-stats">
						<CardBody>
							<Row>
								<Col md="4" xs="5">
									<div className="icon-big text-center">
										<FaRegClock className="text-info" />
									</div>
								</Col>
								<Col md="8" xs="7">
									<div className="numbers">
										<p className="card-category">Pending Approvals</p>
										<CardTitle tag="p">
											{isLoading ? (
												<FaSpinner size={12} color="#ccc" className="fa-spin" />
											) : data.total_clearances_pending_approval != null ? (
												data.total_clearances_pending_approval > 9999 ? (
													`${numberToIntlFormat(9999)}+`
												) : (
													numberToIntlFormat(data.total_clearances_pending_approval)
												)
											) : (
												'?'
											)}
										</CardTitle>
										<p />
									</div>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<hr />
							<div className="stats">
								<Link to={'../member?query=doc-pending'}>
									<i className="fa fa-list" />
									View All
								</Link>
							</div>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
