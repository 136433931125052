// Node modules
import * as Sentry from '@sentry/react';

// Types
export interface AdministratorRoleCategory {
	id: string;
	name: string;
	tooltip?: string;
}

export interface AdministratorRolePermission {
	id: string;
	name: string;
	permissions: Array<AdministratorRolePermissionEntry>;
}

export interface AdministratorRolePermissionEntry {
	id: string;
	category_id: string;
	name: string;
	endpoint_method: string;
	endpoint_uri: string;
}

export interface AdministratorRoleDB {
	docID: string;
	organization: string; // This is for multi-tenancy

	name: string;
	permissions: { [key: string]: { [key: string]: boolean } };

	attributes?: {
		can_modify?: boolean; // Default is "true" meaning that an item can be modified
		can_delete?: boolean; // Default is "true" meaning that an item can be deleted
	};
}

// Exceptions
import { UnauthorizedException } from '../exceptions/UnauthorizedException';
import { InvalidParametersException } from '../exceptions/InvalidParametersException';
import { ResourceNotFoundException } from '../exceptions/ResourceNotFoundException';
import { ForbiddenException } from '../exceptions/ForbiddenException';
import { InternalServerErrorException } from '../exceptions/InternalServerErrorException';
import { ConflictException } from '../exceptions/ConflictException';
import { FailedToFetchException } from '../exceptions/FailedToFetchException';

// Helpers
import { retryUnauthorizedRequestAfterRefresh } from '..';

export default class AdministratorsRoles {
	async listPermissions(token: string): Promise<{
		categories: Array<AdministratorRoleCategory>;
		roles: Array<AdministratorRolePermission>;
	}> {
		try {
			const response = await fetch(`/api/v1/admin/administrators/roles/permissions`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.listPermissions(newToken);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async list(token: string): Promise<Array<AdministratorRoleDB>> {
		try {
			const response = await fetch(`/api/v1/admin/administrators/roles`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj.records;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.list(newToken);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async get(token: string, docID: string): Promise<AdministratorRoleDB> {
		try {
			const response = await fetch(`/api/v1/admin/administrators/roles/${docID}`, {
				method: 'GET',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.get(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async create(token: string, data: AdministratorRoleDB): Promise<AdministratorRoleDB> {
		try {
			const response = await fetch(`/api/v1/admin/administrators/roles`, {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.create(newToken, data);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 409) {
				throw new ConflictException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async update(token: string, docID: string, data: AdministratorRoleDB): Promise<AdministratorRoleDB> {
		try {
			const response = await fetch(`/api/v1/admin/administrators/roles/${docID}`, {
				method: 'PUT',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(data),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.update(newToken, docID, data);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async delete(token: string, docID: string): Promise<void> {
		try {
			const response = await fetch(`/api/v1/admin/administrators/roles/${docID}`, {
				method: 'DELETE',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
				}),
			});

			// Check the response
			if (response.status === 200) {
				return;
			} else if (response.status === 400) {
				const respObj = await response.json();
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.delete(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				const respObj = await response.json();
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				const respObj = await response.json();
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				const respObj = await response.json();
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}

	async copy(token: string, docID: string): Promise<AdministratorRoleDB> {
		try {
			const response = await fetch(`/api/v1/admin/administrators/roles/${docID}/copy`, {
				method: 'POST',
				headers: new Headers({
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				}),
			});

			// Check the response
			const respObj = await response.json();

			if (response.status === 200) {
				return respObj;
			} else if (response.status === 400) {
				throw new InvalidParametersException(respObj.message);
			} else if (response.status === 401) {
				try {
					const newToken = await retryUnauthorizedRequestAfterRefresh();
					return this.copy(newToken, docID);
				} catch (error) {
					if (error instanceof UnauthorizedException) {
						throw new UnauthorizedException('Request response retry returned unauthorized');
					} else {
						throw error;
					}
				}
			} else if (response.status === 403) {
				throw new ForbiddenException(respObj.message);
			} else if (response.status === 404) {
				throw new ResourceNotFoundException(respObj.message);
			} else if (response.status === 500) {
				throw new InternalServerErrorException(respObj.message);
			} else {
				const error = new Error('Unknown error');
				Sentry.captureException(error);
				throw error;
			}
		} catch (err) {
			if (err instanceof TypeError && err.message === 'Failed to fetch') {
				throw new FailedToFetchException();
			}

			throw err;
		}
	}
}
